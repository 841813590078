<template lang="html">
  <div v-if="pageList">
    <Title spacer="true" />

    <div class="Grid" v-if="pageData.content">
      <div class="ContentTop" v-if="pageData.options.content">
        <Content :content="pageData.content" />

        <div v-if="pageData.id == 5" class="adtnavi">
          <router-link :to="{ name: 'arc-timeline' }" class="arcbtn">
            Timeline
          </router-link>
          <router-link
            :to="{ path: '/press/arc-de-triomphe-wrapped/' }"
            class="arcbtn"
          >
            Press
          </router-link>
        </div>

        <Bloomberg v-if="pageData.id == 5" />
      </div>
    </div>

    <section v-if="pageData.options.list" class="List">
      <ul>
        <li v-for="entry in pageList" :key="entry.id">
          <ListEntry :entry="entry" v-if="entry.is_top == 0" />

          <Container
            v-if="entry._container"
            v-for="container in entry._container"
            :key="container.id"
            :container="container"
            :images="entry._images"
          />

          <ListEntry :entry="entry" v-if="entry.is_top == 1" />
        </li>
      </ul>
    </section>

    <section v-else class="Artworks">
      <Container
        v-for="container in pageData._container"
        :key="container.id"
        :container="container"
        :images="pageData._images"
      />
    </section>

    <Loader v-if="!pageListMeta.finished" />
  </div>
</template>

<script>
import Title from "@/components/Elements/Title.vue";
import Content from "@/components/Elements/Content.vue";
import Container from "@/components/Container/Container.vue";
import ListEntry from "@/components/Container/ListEntry.vue";
import Loader from "@/components/Elements/Loader.vue";
import Bloomberg from "@/components/Bloomberg.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Title,
    Content,
    Container,
    ListEntry,
    Loader,
    Bloomberg,
  },
  computed: {
    ...mapGetters(["pageData", "pageList", "pageListMeta"]),
  },
  methods: {
    ...mapActions(["fetchItems"]),
  },
  created() {
    if (this.pageData.options.list) this.$store.state.hideFooter = true;
    this.fetchItems({
      table: this.pageData.options.items.t,
      target: "setPageList",
      query: this.pageData.options.items.query,
    });
  },
};
</script>

<style scoped>
.adtnavi {
  position: relative;
  top: -1px;
  border-top: 1px solid white;
}

@media only screen and (min-width: 600px) {
  .ContentTop {
    grid-column-start: 3;
    grid-column-end: 11;
    margin-bottom: 24px;
  }

  .adtnavi {
    position: relative;
    top: -1;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 12px;
  }

  .arcbtn:hover {
    background: #222929;
    color: white;
  }
}

@media only screen and (min-width: 768px) {
  .ContentTop {
    grid-column-start: 5;
    grid-column-end: 11;
    margin-bottom: 36px;
  }
}
</style>
